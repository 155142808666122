import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import menueIcon from "../icons/app-11173.png";
import { animated, useSpring, config } from "react-spring";
import { Link } from "react-router-dom";
import backGr from "../backgrounds/back4_2.jpg";
import instaLogo from "../icons/instaLogo.png";

const NavbarDiv = styled.div`
  font-family: "rosemary", sans-serif;
  display: flex;
  width: 76vw;
  height: 85px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  background-color: #c6dce4;
  border: 1px solid #98cadd;
  box-shadow: 3px 3px 10px black;
`;
const NavbarList = styled.div`
  width: 10%;
  height: 80px;
  margin-top: 8px;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;
const NavLinks = styled(Link)`
  text-decoration: none;
  list-style-type: none;
  color: #5b2f98;
`;
function MobileNavbar({ s_height, s_width }) {
  const [menuAnim, setMenuAnim] = useSpring(() => ({
    height: "40px",
    width: "40px",
    marginLeft: "14px",
    transform: "rotate(0deg)",
    pointerEvents: "all",
    cursor: "pointer",
  }));
  const listSetter = useRef(false);
  const [listAnim, setListAnim] = useSpring(() => ({
    opacity: "0",
    y: -30,
    pointerEvents: "none",
    boxShadow: "3px 3px 10px black",
  }));
  return (
    <div
      style={{
        width: "100%",
        height: "100px",
        backgroundImage: `url(${backGr})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        display: "flex",
        justifyContent: "flex-end",
        border: "1px solid #7afdff",
        zIndex: 99,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          justifySelf: "flex-start",
          display: "flex",
          flexDirection: "column",
          paddingTop: "5px",
          paddingLeft: "2vw",
        }}
      >
        <NavbarDiv>
          <animated.img
            src={menueIcon}
            alt="menueIcon"
            style={menuAnim}
            onClick={(info) => {
              let rotate = info.target.style.transform;
              setMenuAnim.set({ pointerEvents: "none" });
              let timeout = setTimeout(() => {
                setMenuAnim.set({ pointerEvents: "all" });
              }, 700);
              if (rotate == "rotate(360deg)") {
                rotate = "rotate(0deg)";
                setMenuAnim.set({ transform: `rotate(0deg)` });
              }
              switch (rotate) {
                case "rotate(0deg)":
                  setMenuAnim.stop();
                  setMenuAnim.start({ transform: `rotate(90deg)` });
                  break;
                case "rotate(90deg)":
                  setMenuAnim.stop();
                  setMenuAnim.start({ transform: `rotate(180deg)` });
                  break;
                case "rotate(180deg)":
                  setMenuAnim.stop();
                  setMenuAnim.start({ transform: `rotate(270deg)` });
                  break;
                case "rotate(270deg)":
                  setMenuAnim.stop();
                  setMenuAnim.start({ transform: `rotate(360deg)` });
                  break;
              }
              if (listSetter.current == false) {
                setListAnim.stop();
                setListAnim.start({ opacity: "1", y: 0 });
                setListAnim.set({ pointerEvents: "all" });
                listSetter.current = true;
              } else {
                setListAnim.stop();
                setListAnim.start({
                  opacity: "0",
                  y: -30,
                });
                setListAnim.set({ pointerEvents: "none" });
                listSetter.current = false;
              }
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "20%",
              fontSize: "23px",
            }}
          >
            <NavLinks
              to="/"
              onClick={() => {
                setListAnim.stop();
                setListAnim.set({
                  opacity: "0",
                  y: -30,
                  pointerEvents: "none",
                });

                listSetter.current = false;
              }}
            >
              <h2>Kim-gallery</h2>
            </NavLinks>
          </div>
        </NavbarDiv>
        <NavbarList>
          <animated.div
            style={{
              borderRadius: "50px",
              width: "100px",

              backgroundColor: "#c6dce4",
              border: "1px solid #9dc8d8",
              padding: "5px",
              paddingLeft: "10px",
              fontSize: "15px",
              fontWeight: "bold",
              ...listAnim,
            }}
            onClick={() => {
              setListAnim.stop();
              setListAnim.set({
                opacity: "0",
                y: -30,
                pointerEvents: "none",
              });

              listSetter.current = false;
            }}
          >
            <NavLinks to="/myWork">
              <div style={{ width: "100%" }}>Art Work</div>
            </NavLinks>
          </animated.div>
          <animated.div
            style={{
              borderRadius: "50px",
              width: "100px",

              backgroundColor: "#c6dce4",
              border: "1px solid #9dc8d8",
              padding: "5px",
              paddingLeft: "10px",
              fontSize: "15px",
              fontWeight: "bold",
              ...listAnim,
            }}
            onClick={() => {
              setListAnim.stop();
              setListAnim.set({
                opacity: "0",
                y: -30,
                pointerEvents: "none",
              });

              listSetter.current = false;
            }}
          >
            <NavLinks to="/AboutMe">
              <div style={{ width: "100%" }}>About Me</div>
            </NavLinks>
          </animated.div>
          <animated.div
            style={{
              borderRadius: "50px",
              width: "100px",

              backgroundColor: "#c6dce4",
              border: "1px solid #9dc8d8",
              padding: "5px",
              paddingLeft: "10px",
              fontSize: "15px",
              fontWeight: "bold",
              ...listAnim,
            }}
            onClick={() => {
              setListAnim.stop();
              setListAnim.set({
                opacity: "0",
                y: -30,
                pointerEvents: "none",
              });

              listSetter.current = false;
            }}
          >
            <NavLinks to="/Contact">
              <div style={{ width: "100%" }}>Contact</div>
            </NavLinks>
          </animated.div>
        </NavbarList>
      </div>
      <div
        className="waudh"
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          justifySelf: "flex-end",
          alignItems: "flex-end",
          paddingRight: "5px",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            width: "45px",
            height: "45px",
            display: "flex",
            padding: "0",
          }}
        >
          <a href="https://www.instagram.com/kimiadoust1182">
            <img
              src={instaLogo}
              alt="instagram"
              title="my Instagram"
              style={{ width: "100%", height: "100%" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default MobileNavbar;
