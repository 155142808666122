import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import menueIcon from "../icons/app-11173.png";
import { animated, useSpring, config } from "react-spring";
import { Link } from "react-router-dom";
import backGr from "../backgrounds/back4_2.jpg";
import instaLogo from "../icons/instaLogo.png";

const NavbarDiv = styled.div`
  font-family: "rosemary", sans-serif;
  display: flex;
  width: 45%;
  height: 60px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  background-color: #c6dce4;
  box-shadow: 5px 5px 10px black;
`;
const NavbarList = styled.div`
  width: 10%;
  height: 80px;
  margin-top: 8px;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;
const NavLinks = styled(Link)`
  text-decoration: none;
  list-style-type: none;
  color: #5b2f98;
`;
function Navbar({ s_height, s_width }) {
  const refTop = useRef(false);
  const checker = useRef(false);
  const [navOf, setNavOf] = useState(false);
  const [leftOf, setLeftOf] = useState();
  useEffect(() => {
    navHook();
    window.addEventListener("scroll", navHook);

    return () => {
      window.removeEventListener("scroll", navHook);
    };
  }, []);

  const navHook = () => {
    ////console.log(document.body.clientWidth * 0.1);
    if (!checker.current) {
      if (window.scrollY >= refTop.current.offsetTop + 12) {
        //setLeftOf()
        setNavOf(true);
      }
    }
    if (window.scrollY < refTop.current.offsetTop + 12) {
      checker.current = false;
      setNavOf(false);
    }
    if (window.scrollY >= s_height) {
      checker.current = true;
      setNavOf(false);
    }
    ////console.log(w_height);
  };
  const [menuAnim, setMenuAnim] = useSpring(() => ({
    height: "40px",
    width: "40px",
    marginLeft: "20px",
    transform: "rotate(0deg)",
    pointerEvents: "all",
    cursor: "pointer",
    
  }));
  const listSetter = useRef(false);
  const [listAnim, setListAnim] = useSpring(() => ({
    opacity: "0",
    y: -30,
    borderRadius: "50px",
    width: "100%",
    pointerEvents: "none",
    backgroundColor: "#c6dce4",
    border: "1px solid #9dc8d8",
    padding: "5px",
    paddingLeft: "10px",
    fontSize: "15px",
    fontWeight: "bold",
    boxShadow: "5px 5px 10px black",
  }));
  return (
    <div
      style={{
        width: "100%",
        height: "100px",
        paddingTop: "20px",
        backgroundImage: `url(${backGr})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #7afdff",
        position: "relative",
        zIndex: navOf ? 1 : 99,
      }}
    >
      <div
        ref={refTop}
        style={{
          width: "80%",
          height: "100px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          paddingLeft: "10%",
          position: navOf ? "fixed" : "static",
          top: "0",
          left: document.body.clientWidth * 0.01 - 10,
        }}
      >
        <NavbarDiv
          style={{
            border: navOf ? "5px solid #98cadd" : "1px solid #98cadd",
          }}
        >
          <animated.img
            src={menueIcon}
            alt="menueIcon"
            style={menuAnim}
            onClick={(info) => {
              let rotate = info.target.style.transform;
              setMenuAnim.set({ pointerEvents: "none" });
              let timeout = setTimeout(() => {
                setMenuAnim.set({ pointerEvents: "all" });
              }, 700);
              if (rotate == "rotate(360deg)") {
                rotate = "rotate(0deg)";
                setMenuAnim.set({ transform: `rotate(0deg)` });
              }
              switch (rotate) {
                case "rotate(0deg)":
                  setMenuAnim.stop();
                  setMenuAnim.start({ transform: `rotate(90deg)` });
                  break;
                case "rotate(90deg)":
                  setMenuAnim.stop();
                  setMenuAnim.start({ transform: `rotate(180deg)` });
                  break;
                case "rotate(180deg)":
                  setMenuAnim.stop();
                  setMenuAnim.start({ transform: `rotate(270deg)` });
                  break;
                case "rotate(270deg)":
                  setMenuAnim.stop();
                  setMenuAnim.start({ transform: `rotate(360deg)` });
                  break;
              }
              if (listSetter.current == false) {
                setListAnim.stop();
                setListAnim.start({ opacity: "1", y: 0 });
                setListAnim.set({ pointerEvents: "all" });
                listSetter.current = true;
              } else {
                setListAnim.stop();
                setListAnim.start({
                  opacity: "0",
                  y: -30,
                });
                setListAnim.set({ pointerEvents: "none" });
                listSetter.current = false;
              }
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "40%",
              fontSize: "25px",
            }}
          >
            <NavLinks
              to="/"
              onClick={() => {
                setListAnim.stop();
                setListAnim.set({
                  opacity: "0",
                  y: -30,
                  borderRadius: "50px",
                  width: "100%",
                  backgroundColor: "#c6dce4",
                  border: "1px solid #9dc8d8",
                  padding: "5px",
                });
                setListAnim.set({ pointerEvents: "none" });
                listSetter.current = false;
              }}
            >
              <h2 style={{ fontSize: "2.5vw" }}>Kim-gallery</h2>
            </NavLinks>
          </div>
        </NavbarDiv>
        <NavbarList>
          <animated.div
            style={listAnim}
            onClick={() => {
              setListAnim.stop();
              setListAnim.set({
                opacity: "0",
                y: -30,
                borderRadius: "50px",
                width: "100%",
                backgroundColor: "#c6dce4",
                border: "1px solid #9dc8d8",
                padding: "5px",
              });
              setListAnim.set({ pointerEvents: "none" });
              listSetter.current = false;
            }}
          >
            <NavLinks to="/myWork">
              <div style={{ width: "100%" }}>Art Work</div>
            </NavLinks>
          </animated.div>
          <animated.div
            style={listAnim}
            onClick={() => {
              setListAnim.stop();
              setListAnim.set({
                opacity: "0",
                y: -30,
                borderRadius: "50px",
                width: "100%",
                backgroundColor: "#c6dce4",
                border: "1px solid #9dc8d8",
                padding: "5px",
              });
              setListAnim.set({ pointerEvents: "none" });
              listSetter.current = false;
            }}
          >
            <NavLinks to="/AboutMe">
              <div style={{ width: "100%" }}> About Me</div>
            </NavLinks>
          </animated.div>
          <animated.div
            style={listAnim}
            onClick={() => {
              setListAnim.stop();
              setListAnim.set({
                opacity: "0",
                y: -30,
                borderRadius: "50px",
                width: "100%",
                backgroundColor: "#c6dce4",
                border: "1px solid #9dc8d8",
                padding: "5px",
              });
              setListAnim.set({ pointerEvents: "none" });
              listSetter.current = false;
            }}
          >
            <NavLinks to="/Contact">
              <div style={{ width: "100%" }}>Contact</div>
            </NavLinks>
          </animated.div>
        </NavbarList>
      </div>
      <div
        style={{
          width: "20%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingRight: "20px",
        }}
      >
        <div
          style={{
            width: "50px",
            height: "50px",
            display: "flex",
            padding: "0",
            marginBottom: "20px",
          }}
        >
          <a href="https://www.instagram.com/kimiadoust1182">
            <img
              src={instaLogo}
              alt="instagram"
              title="my Instagram"
              style={{ width: "100%", height: "100%" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
