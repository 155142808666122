import React from "react";
import styled from "styled-components";
import copyrightImg from "../icons/copyright.png";

const FooterDiv = styled.div`
  display: flex;

  align-items: center;
  justifycontent: space-between;
  width: 100%;
  height: 50px;
  background-color: #000000cc;
`;

function Footer() {
  return (
    <FooterDiv>
      <div
        style={{
          width: "30%",
          height: "80%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
          color: "white",
          cursor: "default",
        }}
      >
        <img src={copyrightImg} alt="" style={{ width: "6%", height: "50%" }} />
        2022 Kim-gallery
      </div>
      <div
        style={{
          width: "30%",
          height: "80%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
          color: "white",
          cursor: "default",
          fontSize: "10px",
          textAlign: "center",
        }}
      >
        This site does not collect any personal information
      </div>
      <div
        style={{
          position: "absolute",
          right: "3%",
          width: "30%",
          gap: "5px",
          color: "white",
          cursor: "default",
          fontSize: "13px",
          textAlign: "center",
          zIndex: 99,
        }}
      >
        Made by <a href="http://apollos-webdesign.de/"> Apollos-Webdesign</a>
      </div>
    </FooterDiv>
  );
}

export default Footer;
