import React, { useEffect, useRef, useState } from "react";

import {
  animated,
  useSpring,
  useSprings,
  config,
  useTransition,
} from "react-spring";
import styled from "styled-components";

const HomeDiv = styled.div`
  font-family: "rosemary", sans-serif;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;
function MobileHomePage({ allProds, s_height, s_width }) {
  const checker = useRef(0);
  const picsRef = useRef(0);
  const [border, setBorder] = useState(false);
  var allPics = allProds.map((prod, index) => prod["picture"]);
  const length = useRef();
  useEffect(() => {
    length.current = allProds.length;
  }, [allProds]);
  ////console.log(length.current);
  /*var allPics = [
    allProds[0]["picture"],
    allProds[1]["picture"],
    allProds[2]["picture"],
  ];*/
  const [pics, setPics] = useState(0);

  const transi = useTransition(pics, {
    from: { transform: "translate(-90vw,0)", opacity: 0 },
    enter: { transform: "translate(0,0)", opacity: 1 },
    leave: { transform: "translate(90vw,0)", opacity: 0 },
    config: { duration: 3600 },
    exitBeforeEnter: true,
    onRest: () => {
      setPics((pics + 1) % length.current);
      ////console.log(pics);
    },
    //exitBeforeEnter: true,
  });
  const fromTop1 = useSpring({
    from: { y: -800, opacity: 0 },
    to: { y: 0, opacity: 1 },
    config: { duration: 1500 },
  });
  const fromTop2 = useSpring({
    from: { y: -300, opacity: 0 },
    to: { y: 0, opacity: 1 },
    config: { duration: 3600 },

    onRest: () => setBorder(true),
  });

  return (
    <HomeDiv>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "50px",
        }}
      >
        <animated.div
          style={{
            height: "100vw",
            width: "90vw",
            border: border ? "1px solid #7afdff" : "none",
            overflow: "hidden",
            marginTop: "100px",
            ...fromTop2,
          }}
        >
          {transi((style, item) => {
            ////console.log(item);
            ////console.log(item != length.current ? item + 1 : 0);
            return (
              <animated.div
                key={item.id}
                style={{
                  height: "100vw",
                  width: "180vw",
                  transform: "translate(-50vw,0)",

                  ...style,
                }}
              >
                <img
                  src={
                    item != length.current - 1 ? allPics[item + 1] : allPics[0]
                  }
                  alt=""
                  style={{ height: "100%", width: "50%" }}
                />
                <img
                  src={allPics[item]}
                  alt=""
                  style={{ height: "100%", width: "50%" }}
                />
              </animated.div>
            );
          })}
        </animated.div>
        <animated.div
          style={{
            height: "70vw",
            width: "70vw",
            border: "1px solid #1b4446b0",
            backgroundColor: "#00000057",
            padding: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginBottom: "20px",
            textAlign: "center",
            ...fromTop1,
          }}
        >
          <span style={{ fontSize: "16vw", color: "#7afdff", padding: "0px" }}>
            Hi,
            <br />
            <span style={{ fontSize: "8vw", color: "white" }}>
              and welcome to my gallery page.
            </span>
          </span>
        </animated.div>
      </div>
    </HomeDiv>
  );
}

export default MobileHomePage;
