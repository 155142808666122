import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring, useSprings, config } from "react-spring";
//import { Parallax, ParallaxLayer } from "@react-spring/web";
import styled from "styled-components";
import brush2Icon from "../icons/brush2.png";
import brush1Icon from "../icons/brush1.png";
import arrowDownIcon from "../icons/arrowDown.png";

const MainDiv = styled.div`
  font-family: "edo", sans-serif;
  font-weight: 100;
  width: 80%;
  right: 0;
  left: 0;
  margin: 0 auto;
  position: relative;
  zindex: 99;
`;

function Aboutme({ s_height, s_width }) {
  const puls = useSpring({
    loop: { reverse: true },
    from: {
      y: 0,
      transform: "scale(1)",
    },
    to: {
      y: 30,
      transform: "scale(1.1)",
    },
    config: { duration: 850 },
  });
  const [spin, setSpin] = useSpring(() => ({
    transform: "rotate(0deg) scale(1)",
    config: { duration: 350 },
  }));
  const [onScale, setOnScale] = useSpring(() => ({ transform: "scale(1)" }));
  const [brushAnim, setBrushAnim] = useSprings(4, (index) => ({
    transform: `rotate(${index * 280}deg)`,
    opacity: 0,
    top: s_height / 1.6,
    left: "50%",
    pointerEvents: "none",
    config: { duration: 800 },
  }));
  const [infoAnim, setInfoAnim] = useSpring(() => ({
    x: -20,
    opacity: 0,
    config: { duration: 800 },
  }));
  const imgRef = useRef();
  const [clicked, setClicked] = useState(false);
  const brushanimation = () => {
    let getTop = imgRef.current.offsetTop + imgRef.current.clientHeight / 2;
    let getLeft = imgRef.current.offsetLeft + imgRef.current.clientWidth / 2;
    //console.log(getTop, getLeft);
    setBrushAnim.start((index) => {
      if (index == 0) {
        return {
          top: getTop / 4,
          left: "7%",
          opacity: 1,
          transform: "rotate(360deg)",
        };
      }
      if (index == 1) {
        return {
          top: s_height / 1.6,
          left: "34%",
          opacity: 1,
          transform: "rotate(360deg)",
        };
      }
      if (index == 2) {
        return {
          top: getTop / 4,
          left: "61%",
          opacity: 1,
          transform: "rotate(360deg)",
        };
      }
      if (index == 3) {
        return {
          top: s_height / 1.6,
          left: "87%",
          opacity: 1,
          transform: "rotate(360deg)",
          onRest: () => {
            setTimeout(() => {
              setInfoAnim.stop();
              setInfoAnim.start({
                x: 0,
                opacity: 1,
              });
            }, 100);
          },
        };
      }
    });
  };

  return (
    <div style={{ width: "100%", height: "100%", marginTop: "5%" }}>
      <MainDiv style={{ height: s_height * 1.25 }}>
        <animated.div
          style={{
            position: "absolute",
            width: s_width / 16,
            height: s_width / 16,
            top: "15px",
            left: "49%",
            display: !clicked ? "initial" : "none",

            ...puls,
          }}
        >
          <img
            src={arrowDownIcon}
            alt="Look down"
            title="look down"
            style={{
              width: "100%",
              height: "100%",
              dropShadow: "1px 1px 1px 1px black",
            }}
          />
        </animated.div>
        <animated.div
          ref={imgRef}
          style={{
            position: "absolute",
            width: s_width / 8,
            height: s_width / 8,
            top: s_height / 1.8,
            left: "45%",
            zIndex: 99,
            ...onScale,
          }}
          onMouseOver={() => {
            setOnScale.stop();
            setOnScale.start({ transform: "scale(1.2)" });
          }}
          onMouseOut={() => {
            setOnScale.stop();
            setOnScale.start({ transform: "scale(1)" });
          }}
          onClick={() => {
            setSpin.stop();
            setSpin.start({
              transform: "rotate(1440deg) scale(0)",
            });
            setClicked(true);
            brushanimation();
            ////console.log(imgRef.current.offsetLeft);
            ////console.log(imgRef.current.offsetTop);
          }}
        >
          <animated.img
            src={brush2Icon}
            alt="Click me"
            title="clic me"
            style={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              ...spin,
            }}
          />
        </animated.div>
        <animated.div
          style={{
            display: "flex",
            gap: "0.5vw",
            alignItems: "center",
            position: "absolute",
            width: "4vw",
            height: "4vw",
            ...brushAnim[0],
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={brush1Icon}
              alt="BrushIcon"
              style={{ width: "7vw", height: "7vw" }}
            />
            <animated.div
              style={{
                width: "12vw",
                height: "5vw",
                overflow: "hidden",
                position: "relative",
                textAlign: "center",
                fontSize: "25px",
                ...infoAnim,
              }}
            >
              Hi my name is Kimia
            </animated.div>
          </div>
        </animated.div>
        <animated.div
          style={{
            display: "flex",
            gap: "0.5vw",
            alignItems: "center",
            position: "absolute",
            width: "4vw",
            height: "4vw",
            ...brushAnim[1],
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={brush1Icon}
              alt="BrushIcon"
              style={{ width: "7vw", height: "7vw" }}
            />
            <animated.div
              style={{
                width: "12vw",
                height: "5vw",
                overflow: "hidden",
                position: "relative",
                textAlign: "center",
                fontSize: "25px",
                ...infoAnim,
              }}
            >
              I am from the UK
            </animated.div>
          </div>
        </animated.div>
        <animated.div
          style={{
            display: "flex",
            gap: "0.5vw",
            alignItems: "center",
            position: "absolute",
            width: "4vw",
            height: "4vw",
            ...brushAnim[2],
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={brush1Icon}
              alt="BrushIcon"
              style={{ width: "7vw", height: "7vw" }}
            />
            <animated.div
              style={{
                width: "12vw",
                height: "5vw",
                overflow: "hidden",
                position: "relative",
                textAlign: "center",
                fontSize: "2vw",
                ...infoAnim,
              }}
            >
              Art is my passion
            </animated.div>
          </div>
        </animated.div>
        <animated.div
          style={{
            display: "none",
            gap: "0.5vw",
            alignItems: "center",
            position: "absolute",
            width: "4vw",
            height: "4vw",
            ...brushAnim[3],
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={brush1Icon}
              alt="BrushIcon"
              style={{ width: "7vw", height: "7vw" }}
            />
            <animated.div
              style={{
                width: "12vw",
                height: "5vw",
                overflow: "hidden",
                position: "relative",
                textAlign: "center",
                fontSize: "25px",
                ...infoAnim,
              }}
            >
              Hi my name is Kimia
            </animated.div>
          </div>
        </animated.div>
      </MainDiv>
    </div>
  );
}

export default Aboutme;
