import { createGlobalStyle } from "styled-components";
import edoWoff from "./fonts/edo.woff";
import edoWoff2 from "./fonts/edo.woff2";
import victorianWoff from "./fonts/Victorian Parlor Vintage.woff";
import victorianWoff2 from "./fonts/Victorian Parlor Vintage.woff2";
import rosemaryWoff from "./fonts/RosemaryRoman.woff";
import rosemaryWoff2 from "./fonts/RosemaryRoman.woff2";
const FontStyles = createGlobalStyle`


@font-face {
  font-family: 'edo';
  src: url(${edoWoff2}) format('woff2'),
       url(${edoWoff}) format('woff');
}
@font-face {
  font-family: 'victorian';
  src: url(${victorianWoff2}) format('woff2'),
       url(${victorianWoff}) format('woff');
}
@font-face {
  font-family: 'rosemary';
  src: url(${rosemaryWoff2}) format('woff2'),
       url(${rosemaryWoff}) format('woff');
}
`;
export default FontStyles;
