import React, { useEffect, useRef, useState } from "react";

import {
  animated,
  useSpring,
  useSprings,
  config,
  useTransition,
} from "react-spring";
import styled from "styled-components";


const HomeDiv = styled.div`
  font-family: "rosemary", sans-serif;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;
function HomePage({ allProds, s_height }) {
  const checker = useRef(0);
  const picsRef = useRef(0);
  const [border, setBorder] = useState(false);
  var allPics = allProds.map((prod, index) => prod["picture"]);
  const length = useRef();
  useEffect(() => {
    length.current = allProds.length;
  }, [allProds]);
  
  const [pics, setPics] = useState(0);

  const transi = useTransition(pics, {
    from: { x: -500 },
    enter: { x: 0 },
    leave: { x: 500 },
    config: { duration: 4000 },

    onRest: () => {
      setPics((pics + 1) % length.current);
      ////console.log(pics);
    },
    //exitBeforeEnter: true,
  });
  const fromRight = useSpring({
    from: { x: 300, opacity: 0 },
    to: { x: 0, opacity: 1 },
    config: { duration: 1500 },
  });
  const fromLeft = useSpring({
    from: { x: -300, opacity: 0 },
    to: { x: 0, opacity: 1 },
    config: { duration: 4000 },
    onRest: () => setBorder(true),
  });

  return (
    <HomeDiv>
      <div
        style={{
          width: "100%",
          height: "800px",
          display: "flex",
          justifyContent: "center",
          gap: "120px",
          paddingTop: "200px",
        }}
      >
        <animated.div
          style={{
            height: "500px",
            width: "500px",
            border: border ? "1px solid #7afdff" : "none",
            overflow: "hidden",
            marginTop: "200px",
            ...fromLeft,
          }}
        >
          {transi((style, item) => {
            ////console.log(item);
            ////console.log(item != length.current ? item + 1 : 0);
            return (
              <animated.div
                key={item.id}
                style={{
                  height: "500px",
                  width: "1000px",
                  transform: "translate(-500px,0)",

                  ...style,
                }}
              >
                <img
                  src={
                    item != length.current - 1 ? allPics[item + 1] : allPics[0]
                  }
                  alt=""
                  style={{ height: "100%", width: "50%" }}
                />
                <img
                  src={allPics[item]}
                  alt=""
                  style={{ height: "100%", width: "50%" }}
                />
              </animated.div>
            );
          })}
        </animated.div>
        <animated.div
          style={{
            width: "400px",
            height: "350px",
            border: "1px solid #1b4446b0",
            backgroundColor: "#00000057",
            padding: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            ...fromRight,
          }}
        >
          <span style={{ fontSize: "80px", color: "#7afdff", padding: "0px" }}>
            Hi,
            <br />
            <span style={{ fontSize: "40px", color: "white" }}>
              and welcome to my gallery page.
            </span>
          </span>
        </animated.div>
      </div>
    </HomeDiv>
  );
}

export default HomePage;
