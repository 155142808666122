import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring, useSprings, config } from "react-spring";
//import { Parallax, ParallaxLayer } from "@react-spring/web";
import styled from "styled-components";
import brush2Icon from "../icons/brush2.png";
import brush1Icon from "../icons/brush1.png";
import arrowDownIcon from "../icons/arrowDown.png";

const MainDiv = styled.div`
  font-family: "edo", sans-serif;
  font-weight: 100;
  width: 80%;
  right: 0;
  left: 0;
  margin: 0 auto;
  position: relative;
`;

function MobileAboutme({ s_height, s_width }) {
  const puls = useSpring({
    loop: { reverse: true },
    from: {
      y: 0,
      transform: "scale(1)",
    },
    to: {
      y: 30,
      transform: "scale(1.1)",
    },
    config: { duration: 850 },
  });
  const [spin, setSpin] = useSpring(() => ({
    transform: "rotate(0deg) scale(1)",
    config: { duration: 350 },
  }));
  const [onScale, setOnScale] = useSpring(() => ({ transform: "scale(1)" }));
  const [brushAnim, setBrushAnim] = useSprings(4, (index) => ({
    transform: `rotate(${index * 60}deg)`,
    opacity: 0,
    top: s_height / 1.6,
    left: "50%",
    pointerEvents: "none",
    config: { duration: 800 },
  }));
  const [infoAnim, setInfoAnim] = useSpring(() => ({
    x: -20,
    opacity: 0,
    config: { duration: 800 },
  }));
  const imgRef = useRef();
  const [clicked, setClicked] = useState(false);
  const brushanimation = () => {
    let getTop = imgRef.current.offsetTop + imgRef.current.clientHeight / 2;
    let getLeft = imgRef.current.offsetLeft + imgRef.current.clientWidth / 2;
    //console.log(getTop, getLeft);
    setBrushAnim.start((index) => {
      if (index == 0) {
        return {
          top: s_height,
          left: "1%",
          opacity: 1,
          transform: "rotate(360deg)",
        };
      }
      if (index == 1) {
        return {
          top: s_height - s_height / 4,
          left: "1%",
          opacity: 1,
          transform: "rotate(360deg)",
        };
      }
      if (index == 2) {
        return {
          top: s_height - s_height / 2,
          left: "1%",
          opacity: 1,
          transform: "rotate(360deg)",
        };
      }
      if (index == 3) {
        return {
          top: s_height - (s_height * 3) / 4,
          left: "1%",
          opacity: 1,
          transform: "rotate(220deg)",
          onRest: () => {
            setTimeout(() => {
              setInfoAnim.stop();
              setInfoAnim.start({
                x: 0,
                opacity: 1,
              });
            }, 100);
          },
        };
      }
    });
  };

  return (
    <div style={{ height: "100 %", marginTop: "5%", overflowX: "hidden" }}>
      <MainDiv style={{ height: "1200px" }}>
        <animated.div
          style={{
            position: "absolute",
            width: "80px",
            height: "80px",
            top: "15px",
            left: s_width / 2.65,
            display: !clicked ? "initial" : "none",
            ...puls,
          }}
        >
          <img
            src={arrowDownIcon}
            alt="Look down"
            title="look down"
            style={{
              width: "100%",
              height: "100%",
              dropShadow: "1px 1px 1px 1px black",
            }}
          />
        </animated.div>
        <animated.div
          ref={imgRef}
          style={{
            position: "absolute",
            width: "150px",
            height: "150px",
            top: "680px",
            left: s_width / 3,
            ...onScale,
          }}
          onMouseOver={() => {
            setOnScale.stop();
            setOnScale.start({ transform: "scale(1.2)" });
          }}
          onMouseOut={() => {
            setOnScale.stop();
            setOnScale.start({ transform: "scale(1)" });
          }}
          onClick={() => {
            setSpin.stop();
            setSpin.start({
              transform: "rotate(1440deg) scale(0)",
            });
            setClicked(true);
            brushanimation();
            ////console.log(imgRef.current.offsetLeft);
            ////console.log(imgRef.current.offsetTop);
          }}
        >
          <animated.img
            src={brush2Icon}
            alt="Click me"
            title="clic me"
            style={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              ...spin,
            }}
          />
        </animated.div>
        <animated.div
          style={{
            display: "flex",
            gap: "0.5vw",
            alignItems: "center",
            position: "absolute",
            width: "75vw",
            height: "35vw",
            ...brushAnim[0],
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={brush1Icon}
              alt="BrushIcon"
              style={{ width: "20vw", height: "20vw" }}
            />
            <animated.div
              style={{
                width: "35vw",
                overflow: "hidden",
                position: "relative",
                textAlign: "center",
                fontSize: "25px",
                ...infoAnim,
              }}
            >
              Art is my passion
            </animated.div>
          </div>
        </animated.div>
        <animated.div
          style={{
            display: "flex",
            gap: "0.5vw",
            alignItems: "center",
            position: "absolute",
            width: "75vw",
            height: "35vw",
            ...brushAnim[1],
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={brush1Icon}
              alt="BrushIcon"
              style={{ width: "20vw", height: "20vw" }}
            />
            <animated.div
              style={{
                width: "35vw",
                overflow: "hidden",
                position: "relative",
                textAlign: "center",
                fontSize: "25px",
                ...infoAnim,
              }}
            >
              I am from the UK
            </animated.div>
          </div>
        </animated.div>
        <animated.div
          style={{
            display: "flex",
            gap: "0.5vw",
            alignItems: "center",
            position: "absolute",
            width: "75vw",
            height: "35vw",
            ...brushAnim[2],
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={brush1Icon}
              alt="BrushIcon"
              style={{ width: "20vw", height: "20vw" }}
            />
            <animated.div
              style={{
                width: "35vw",
                overflow: "hidden",
                position: "relative",
                textAlign: "center",
                fontSize: "25px",
                ...infoAnim,
              }}
            >
              Hi my name is Kimia
            </animated.div>
          </div>
        </animated.div>
        <animated.div
          style={{
            display: "flex",
            gap: "0.5vw",
            alignItems: "center",
            position: "absolute",
            width: "75vw",
            height: "35vw",
            ...brushAnim[3],
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={brush1Icon}
              alt="BrushIcon"
              style={{ width: "25vw", height: "25vw" }}
            />
            <animated.div
              style={{
                width: "35vw",
                overflow: "hidden",
                position: "relative",
                textAlign: "center",
                fontSize: "25px",
                ...infoAnim,
              }}
            ></animated.div>
          </div>
        </animated.div>
      </MainDiv>
    </div>
  );
}

export default MobileAboutme;
