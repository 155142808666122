import React, { useEffect, useRef, useState } from "react";
import {
  animated,
  useSpring,
  useSprings,
  config,
  Parallax,
  ParralaxLayer,
} from "react-spring";
//#338488
import styled from "styled-components";
import brush1Icon from "../icons/brush1.png";
import mailIcon from "../icons/mail.png";
import cashIcon from "../icons/cash.png";
import imageIcon from "../icons/image.png";
import doubleArrowIcon from "../icons/doubleArrow.png";
import backGround from "../backgrounds/back4.jpg";
import xCircle from "../icons/xCircle.png";

const MainDiv = styled.div`
  font-family: "Cantarell", sans-serif;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  margin-bottom: 150px;
`;
const AllProds = styled.div`
  width: 80%;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 120px;
`;
const SingleProd = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid black;
`;

function MobileArtWork({ s_height, s_width, allProds, sendEmail }) {
  const [infoSetter, setInfoSetter] = useState();
  const inputRef = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const [mailInfo, setMailInfo] = useState({
    user_name: "",
    user_email: "",
    subject: "",
    message: "",
    user_prod: "",
  });

  const setter = useRef(99);

  /*const [prodList, setProdList] = useState([
    { id: 1, name: "FirstProd", price: "100", picture: pic1 },
    { id: 2, name: "SecondProd", price: "100", picture: pic2 },
    { id: 3, name: "ThirdProd", price: "100", picture: pic3 },
    { id: 4, name: "FourthProd", price: "100", picture: pic4 },
    { id: 5, name: "FifthProd", price: "100", picture: pic5 },
  ]);*/
  const [prodAnim, setProdAnim] = useSprings(allProds.length, (index) => ({
    height: "100%",
    width: "100%",
    cursor: "pointer",
    position: "relative",
  }));
  const [contactBu, setContactBu] = useSpring(() => ({
    display: "flex",
    gap: "10%",
    alignItems: "center",
    cursor: "pointer",
    transform: "scale(1)",
    x: 0,
    marginTop: "15%",
    color: "#5b2f98",
    fontSize: "3vw",
    fontWeight: "bold",
  }));
  const swipePuls = useSpring({
    loop: { reverse: true },
    from: {
      opacity: 0.5,
      transform: "scale(1)",
    },
    to: {
      opacity: 1,
      transform: "scale(1.2)",
    },

    config: { duration: 850 },
  });
  const [scaler, setScaler] = useSpring(() => ({ transform: "scale(1)" }));
  const [mailShow, setMailShow] = useSpring(() => ({
    opacity: 0,
    display: "none",
  }));
  const [activeProd, setActiveProd] = useState();
  const mailer = useRef(false);
  const onMail = (prodInfo) => {
    mailer.current = !mailer.current;
    let newState = "";
    if (mailer.current) {
      setMailShow.stop();
      setMailShow.set({ display: "flex" });
      setMailShow.start({ opacity: 1 });
      newState = mailInfo;
      newState[
        "user_prod"
      ] = `${prodInfo[0]}  ${prodInfo[1]}£  //  ${prodInfo[2]}`;
      setMailInfo(...newState);
    } else {
      setMailShow.stop();
      setMailShow.start({ opacity: 0 });
      setMailShow.set({ display: "none" });
    }
  };
  return (
    <MainDiv>
      <AllProds>
        {allProds.map((prods, ind) => (
          <SingleProd>
            <animated.div
              style={prodAnim[ind]}
              onClick={() => {
                ////console.log(prodAnim[ind]);
                setProdAnim.stop();
                setProdAnim.start((index) => {
                  if (index != ind) {
                    return { width: "100%", height: "100%" };
                  }
                  if (setter.current != ind) {
                    setInfoSetter(ind);
                    setter.current = ind;
                    return { width: "60%", height: "100%" };
                  } else {
                    setInfoSetter(99999);
                    setter.current = 99;
                    return { width: "100%", height: "100%" };
                  }
                });
              }}
            >
              <animated.img
                src={doubleArrowIcon}
                alt="click here"
                title="click here"
                style={{
                  position: "absolute",
                  width: "8%",
                  height: "8%",
                  zIndex: "10",
                  right: "2%",
                  top: "50%",
                  ...swipePuls,
                }}
              />
              <div
                style={{
                  position: "relative",
                  zIndex: 2,
                  width: "100%",
                  height: "60vw",
                  backgroundImage: `url(${prods.picture})`,
                  backgroundPosition: "center",
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <img
                  src={prods.picture}
                  alt={`pic${prods.id}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                  }}
                />
              </div>
            </animated.div>
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                right: 0,
                zIndex: 1,
                backgroundColor: "#a9d3e58a",
              }}
            >
              <div
                style={{
                  width: "30%",
                  position: "absolute",
                  right: 12,
                  top: "15px",
                  display: "flex",
                  flexDirection: "column",
                  visibility: infoSetter == ind ? "visible" : "hidden",
                }}
              >
                <animated.div
                  style={{ width: "8vw", height: "8vw", ...scaler }}
                  onMouseOver={() => {
                    setScaler.stop();
                    setScaler.start({ transform: "scale(1.15)" });
                  }}
                  onMouseOut={() => {
                    setScaler.stop();
                    setScaler.start({ transform: "scale(1)" });
                  }}
                >
                  <a href={prods.picture}>
                    <img
                      src={imageIcon}
                      alt="WholeImage"
                      title="whole image"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </a>
                </animated.div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10%",
                    color: "#5b2f98",
                    fontSize: "2.5vw",
                    fontWeight: "bold",
                    width: "25vw",
                    height: "18vw",
                  }}
                >
                  <img
                    src={brush1Icon}
                    alt="Name"
                    title="Name"
                    style={{
                      width: "6vw",
                      height: "6vw",
                    }}
                  />
                  <div style={{ fontFamily: "Cantarell" }}>{prods.name}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10%",
                    alignItems: "center",
                    color: "#5b2f98",
                    fontSize: "3vw",
                    fontWeight: "bold",
                  }}
                >
                  <img
                    src={cashIcon}
                    alt="Price"
                    title="Price"
                    style={{
                      width: "6vw",
                      height: "6vw",
                    }}
                  />
                  <h3 style={{ fontFamily: "Cantarell" }}>{prods.price}£</h3>
                </div>
                <animated.div
                  style={contactBu}
                  onMouseOver={() => {
                    setContactBu.stop();
                    setContactBu.start({ transform: "scale(1.1)", x: 25 });
                  }}
                  onMouseOut={() => {
                    setContactBu.stop();
                    setContactBu.start({ transform: "scale(1)", x: 0 });
                  }}
                  onClick={() => {
                    setActiveProd(prods.picture);
                    onMail([prods.name, prods.price, prods.picture]);
                  }}
                >
                  <img
                    src={mailIcon}
                    alt="contact"
                    title="contact me"
                    style={{
                      width: "6vw",
                      height: "6vw",
                    }}
                  />
                  <span style={{ fontFamily: "Cantarell" }}>
                    {" "}
                    Contact Me {">>>"}
                  </span>
                </animated.div>
              </div>
            </div>
          </SingleProd>
        ))}
      </AllProds>
      <animated.div
        style={{
          position: "fixed",
          bottom: "0",
          right: "0",
          ...mailShow, //mailShow ? "flex" : "none",
          width: "100vw",
          height: "100vh",
          backgroundColor: "#000000d6",
          zIndex: 100,
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "7vw",
            height: "7vw",
            top: "20px",
            right: "20px",
            cursor: "pointer",
            zIndex: 100,
          }}
          onClick={() => {
            onMail();
            let newState = mailInfo;
            newState["user_prod"] = "";
            setMailInfo(...newState);
          }}
        >
          <img
            src={xCircle}
            alt="xCircle"
            title="cancel"
            style={{ height: "100%", width: "100%" }}
          ></img>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "2%",
            right: 0,
            left: 0,
            margin: "auto",
            display: "flex", //mailShow ? "flex" : "none",
            flexDirection: "column",
            width: "97vw",
            height: "97vh",
            backgroundImage: `url(${backGround})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            gap: "1vw",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "70%",
              display: "flex",
              gap: "5px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "4vw",
                color: "#7afdff",
                fontWeight: "bold",
              }}
            >
              Product:{" "}
            </p>
            <div style={{ width: "120px", height: "auto" }}>
              <img
                src={activeProd}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "4.5vw",
                color: "#7afdff",
                fontWeight: "bold",
              }}
            >
              name:
            </p>
            <input
              ref={inputRef}
              type="text"
              name="subject"
              style={{
                backgroundColor: "#022b2cd9",
                color: "white",
                borderRadius: "10px",
                border: "#7afdff solid 1px",
                outline: "none",
                width: "50%",
                height: "30px",
                fontSize: "100%",
              }}
              placeholder="your name"
              onChange={(e) => {
                let newState = mailInfo;
                newState["user_name"] = e.target.value;
                setMailInfo(...newState);
              }}
            />
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "4.5vw",
                color: "#7afdff",
                fontWeight: "bold",
              }}
            >
              email:
            </p>
            <input
              ref={inputRef2}
              type="text"
              name="subject"
              style={{
                backgroundColor: "#022b2cd9",
                color: "white",
                borderRadius: "10px",
                border: "#7afdff solid 1px",
                outline: "none",
                width: "90%",
                height: "30px",
                fontSize: "100%",
              }}
              placeholder="john@example.de"
              onChange={(e) => {
                let newState = mailInfo;
                newState["user_email"] = e.target.value;
                setMailInfo(...newState);
              }}
            />
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "4.5vw",
                color: "#7afdff",
                fontWeight: "bold",
              }}
            >
              subject:
            </p>
            <input
              ref={inputRef3}
              type="text"
              name="subject"
              style={{
                backgroundColor: "#022b2cd9",
                color: "white",
                borderRadius: "10px",
                border: "#7afdff solid 1px",
                outline: "none",
                width: "50%",
                height: "30px",
                fontSize: "100%",
              }}
              onChange={(e) => {
                let newState = mailInfo;
                newState["subject"] = e.target.value;
                setMailInfo(...newState);
              }}
            />
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              gap: "3px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "4.5vw",
                color: "#7afdff",
                fontWeight: "bold",
              }}
            >
              message:
            </p>
            <textarea
              ref={inputRef4}
              name="message"
              style={{
                backgroundColor: "#022b2cd9",
                color: "white",
                borderRadius: "10px",
                border: "#7afdff solid 1px",
                outline: "none",
                width: "100%",
                height: "150px",
                fontSize: "100%",
                resize: "none",
              }}
              onChange={(e) => {
                let newState = mailInfo;
                newState["message"] = e.target.value;
                setMailInfo(...newState);
              }}
            ></textarea>
          </div>
          <button
            style={{
              backgroundColor: "#7afdff",
              color: "#022b2cd9",
              borderRadius: "6px",
              border: "#022b2cd9 solid 1px",
              outline: "none",
              width: "30%",
              height: "30px",
              fontSize: "150%",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.preventDefault();
              if (mailInfo["user_name"]) {
                if (mailInfo["user_email"]) {
                  if (mailInfo["message"]) {
                    sendEmail(mailInfo);
                    inputRef.current.value = "";
                    inputRef2.current.value = "";
                    inputRef3.current.value = "";
                    inputRef4.current.value = "";
                    setMailShow.stop();
                    setMailShow.start({ opacity: 0 });
                    setMailShow.set({ display: "none" });
                    setMailInfo({
                      user_name: "",
                      user_email: "",
                      subject: "",
                      message: "",
                      user_prod: "",
                    });
                    mailer.current = false;
                  } else {
                    alert("Please enter a message");
                  }
                } else {
                  alert("Please enter your email address");
                }
              } else {
                alert("Please enter your name");
              }

              ////console.log(inputRef4.current.value);
            }}
          >
            send
          </button>
        </div>
      </animated.div>
    </MainDiv>
  );
}

export default MobileArtWork;
