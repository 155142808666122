import React, { useEffect, useRef, useState } from "react";
import {
  animated,
  useSpring,
  useSprings,
  config,
  useTransition,
} from "react-spring";
import styled from "styled-components";
import dropDownArrow from "../icons/arrowDown.png";

const MainDiv = styled.div`
  width: 80%;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 90px;
`;
function Contact({ s_height, s_width, allProds, sendEmail }) {
  const [mailInfo, setMailInfo] = useState({
    user_name: "",
    user_email: "",
    subject: "",
    message: "",
    user_prod: "",
  });
  const inputRef = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const [downScroll, setdownScroll] = useState(false);
  var allPics = allProds.map((prod, index) => prod["picture"]);
  ////console.log(allPics);
  const length = useRef();
  useEffect(() => {
    length.current = allProds.length;
  }, [allProds]);
  ////console.log(allPics);

  const [pics, setPics] = useState(0);

  const transi = useTransition(pics, {
    from: { opacity: 0.1 },
    enter: { opacity: 1 },
    leave: { opacity: 0.1 },
    config: { duration: 3000 },

    exitBeforeEnter: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setPics((index) => (index + 1) % length.current);
      //console.log("ughawd");
      ////console.log(pics);
    }, 6000);

    window.addEventListener("scroll", controllNav);
    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", controllNav);
    };
  }, []);
  const [slideIn, setSlideIn] = useSprings(5, (index) => ({
    width: "60%",
    opacity: 0,
    config: { duration: 800 },
  }));
  const controllNav = () => {
    
    if (
      window.scrollY >= inputRef.current.offsetTop - s_height &&
      window.scrollY < inputRef2.current.offsetTop - s_height
    ) {
      setSlideIn.start((index) => {
        if (index == 0) {
          return { width: "100%", opacity: 1 };
        }
      });
    }
    if (
      window.scrollY >= inputRef2.current.offsetTop - s_height &&
      window.scrollY < inputRef3.current.offsetTop - s_height
    ) {
      setSlideIn.start((index) => {
        if (index == 1) {
          setdownScroll(true);
          return { width: "100%", opacity: 1 };
        }
      });
    }
    if (
      window.scrollY >= inputRef3.current.offsetTop - s_height &&
      window.scrollY < inputRef4.current.offsetTop - s_height
    ) {
      setSlideIn.start((index) => {
        if (index == 2) {
          return { width: "100%", opacity: 1 };
        }
      });
    }
    if (
      window.scrollY >= inputRef4.current.offsetTop - s_height &&
      window.scrollY < inputRef5.current.offsetTop - s_height
    ) {
      setSlideIn.start((index) => {
        if (index == 3) {
          return { width: "100%", opacity: 1 };
        }
      });
    }
    if (window.scrollY >= inputRef5.current.offsetTop - s_height) {
      setSlideIn.start((index) => {
        if (index == 4) {
          return { width: "100%", opacity: 1 };
        }
      });
    }
  };
  const puls = useSpring({
    loop: { reverse: true },
    from: {
      y: 0,
    },
    to: {
      y: 30,
    },
    config: { duration: 850 },
    cancel: downScroll ? true : false,
  });
  ////console.log(mailInfo);
  return (
    <div>
      <MainDiv>
        <div
          style={{
            width: "100%",
            height: s_height / 1.2,

            justifySelf: "center",
            alignSelf: "center",
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <animated.div
            style={{
              width: "10%",
              alignSelf: "center",
              ...puls,
              visibility: downScroll ? "hidden" : "visible",
            }}
          >
            <img src={dropDownArrow} alt="" style={{ width: "100%" }} />
          </animated.div>
          <div
            style={{
              width: "60%",
              overflow: "hidden",
            }}
          >
            {transi((style, it) => {
              ////console.log(it);
              return (
                <animated.img
                  src={allPics[it]}
                  style={{
                    width: "100%",
                    height: "auto",
                    ...style,
                  }}
                />
              );
            })}
          </div>
          <animated.div
            style={{
              width: "10%",
              alignSelf: "center",
              ...puls,
              visibility: downScroll ? "hidden" : "visible",
            }}
          >
            <img src={dropDownArrow} alt="" style={{ width: "100%" }} />
          </animated.div>
        </div>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            marginBottom: "20px",
            gap: s_height / 6,
          }}
        >
          <animated.div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              ...slideIn[0],
            }}
          >
            <label
              style={{
                color: "#7afdff",
                fontWeight: "750",
                fontSize: "150%",
              }}
            >
              Name:
            </label>
            <input
              ref={inputRef}
              type="text"
              name="user_name"
              style={{
                backgroundColor: "#022b2cd9",
                color: "white",
                borderRadius: "10px",
                border: "#7afdff solid 1px",
                outline: "none",
                width: "80%",
                height: s_height / 20,
                fontSize: "150%",
                boxShadow: "5px 5px 10px black",
              }}
              placeholder="your name"
              onChange={(e) => {
                let newState = mailInfo;
                newState["user_name"] = e.target.value;
                setMailInfo(...newState);
              }}
            />
          </animated.div>
          <animated.div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              ...slideIn[1],
            }}
          >
            <label
              style={{ color: "#7afdff", fontWeight: "750", fontSize: "150%" }}
            >
              email:
            </label>{" "}
            <input
              ref={inputRef2}
              type="email"
              name="user_email"
              style={{
                backgroundColor: "#022b2cd9",
                color: "white",
                borderRadius: "10px",
                border: "#7afdff solid 1px",
                outline: "none",
                width: "80%",
                height: s_height / 20,
                fontSize: "150%",
                boxShadow: "5px 5px 10px black",
              }}
              placeholder="john@example.de"
              onChange={(e) => {
                let newState = mailInfo;
                newState["user_email"] = e.target.value;
                setMailInfo(...newState);
              }}
            />
          </animated.div>
          <animated.div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              ...slideIn[2],
            }}
          >
            <label
              style={{ color: "#7afdff", fontWeight: "750", fontSize: "150%" }}
            >
              Subject:
            </label>{" "}
            <input
              ref={inputRef3}
              type="text"
              name="subject"
              style={{
                backgroundColor: "#022b2cd9",
                color: "white",
                borderRadius: "10px",
                border: "#7afdff solid 1px",
                outline: "none",
                width: "80%",
                height: s_height / 20,
                fontSize: "150%",
                boxShadow: "5px 5px 10px black",
              }}
              onChange={(e) => {
                let newState = mailInfo;
                newState["subject"] = e.target.value;
                setMailInfo(...newState);
              }}
            />
          </animated.div>
          <animated.div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              ...slideIn[3],
            }}
          >
            <label
              style={{
                color: "#7afdff",
                fontWeight: "750",
                fontSize: "150%",
              }}
            >
              Message:
            </label>
            <textarea
              ref={inputRef4}
              name="message"
              style={{
                height: s_height / 3,
                resize: "none",
                backgroundColor: "#022b2cd9",
                color: "white",
                borderRadius: "10px",
                border: "#7afdff solid 1px",
                outline: "none",
                width: "85%",
                fontSize: "150%",
                boxShadow: "5px 5px 10px black",
              }}
              onChange={(e) => {
                let newState = mailInfo;
                newState["message"] = e.target.value;
                setMailInfo(...newState);
              }}
            ></textarea>
          </animated.div>
          <animated.div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              ...slideIn[4],
            }}
          >
            <button
              ref={inputRef5}
              style={{
                backgroundColor: "#7afdff",
                color: "#022b2cd9",
                borderRadius: "10px",
                border: "#022b2cd9 solid 1px",
                outline: "none",
                width: "30%",
                height: "50px",
                fontSize: "150%",
                cursor: "pointer",
                boxShadow: "5px 5px 10px black",
              }}
              onClick={(e) => {
                e.preventDefault();
                if (mailInfo["user_name"]) {
                  if (mailInfo["user_email"]) {
                    if (mailInfo["message"]) {
                      sendEmail(mailInfo);
                      inputRef.current.value = "";
                      inputRef2.current.value = "";
                      inputRef3.current.value = "";
                      inputRef4.current.value = "";
                      setMailInfo({
                        user_name: "",
                        user_email: "",
                        subject: "",
                        message: "",
                        user_prod: "",
                      });
                    } else {
                      alert("Please enter a message");
                    }
                  } else {
                    alert("Please enter your email address");
                  }
                } else {
                  alert("Please enter your name");
                }

                ////console.log(inputRef4.current.value);
              }}
            >
              send
            </button>
          </animated.div>
        </form>
      </MainDiv>
    </div>
  );
}

export default Contact;
