import React, { useEffect, useRef, useState } from "react";
import {
  animated,
  useSpring,
  useSprings,
  config,
  Parallax,
  ParralaxLayer,
} from "react-spring";
//#338488
import styled from "styled-components";
import brush1Icon from "../icons/brush1.png";
import mailIcon from "../icons/mail.png";
import cashIcon from "../icons/cash.png";
import imageIcon from "../icons/image.png";
import doubleArrowIcon from "../icons/doubleArrow.png";
import backGround from "../backgrounds/back4.jpg";
import xCircle from "../icons/xCircle.png";

const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 50px;
  margin-bottom: 150px;
`;
const AllProds = styled.div`
  width: 80%;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 120px;
`;
const SingleProd = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid black;
`;

function ArtWork({ s_height, s_width, allProds, sendEmail }) {
  const [infoSetter, setInfoSetter] = useState();
  const inputRef = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const [mailInfo, setMailInfo] = useState({
    user_name: "",
    user_email: "",
    subject: "",
    message: "",
    user_prod: "",
  });
  const [heightLimit, setheightLimit] = useState(false);
  const setter = useRef(99);
  useEffect(() => {
    if (s_height <= 330) {
      setheightLimit(true);
    } else {
      setheightLimit(false);
    }
  }, [s_height]);
  const [prodAnim, setProdAnim] = useSprings(allProds.length, (index) => ({
    height: "100%",
    width: "100%",
    cursor: "pointer",
    position: "relative",
  }));
  const [contactBu, setContactBu] = useSpring(() => ({
    display: "flex",
    gap: "10%",
    alignItems: "center",
    cursor: "pointer",
    transform: "scale(1)",
    x: 0,
    marginTop: "15%",
    color: "#5b2f98",
    fontSize: "30px",
    fontWeight: "bold",
  }));
  const swipePuls = useSpring({
    loop: { reverse: true },
    from: {
      opacity: 0.5,
      transform: "scale(1)",
    },
    to: {
      opacity: 1,
      transform: "scale(1.2)",
    },

    config: { duration: 850 },
  });
  const [scaler, setScaler] = useSpring(() => ({ transform: "scale(1)" }));
  const [mailShow, setMailShow] = useSpring(() => ({
    opacity: 0,
    display: "none",
  }));
  const [activeProd, setActiveProd] = useState();
  const mailer = useRef(false);
  const onMail = (prodInfo) => {
    mailer.current = !mailer.current;
    let newState = "";
    if (mailer.current) {
      setMailShow.stop();
      setMailShow.set({ display: "flex" });
      setMailShow.start({ opacity: 1 });
      newState = mailInfo;
      newState[
        "user_prod"
      ] = `${prodInfo[0]}  ${prodInfo[1]}£  //  ${prodInfo[2]}`;
      setMailInfo(...newState);
    } else {
      setMailShow.stop();
      setMailShow.start({ opacity: 0 });
      setMailShow.set({ display: "none" });
    }
  };
  //console.log
  return (
    <MainDiv>
      <AllProds>
        {allProds.map((prods, ind) => (
          <SingleProd>
            <animated.div
              style={prodAnim[ind]}
              onClick={() => {
                ////console.log(prodAnim[ind]);

                setProdAnim.stop();
                setProdAnim.start((index) => {
                  if (index != ind) {
                    
                    return { width: "100%", height: "100%" };
                  }
                  if (setter.current != ind) {
                    setInfoSetter(ind);
                    setter.current = ind;
                    return { width: "60%", height: "100%" };
                  } else {
                    setInfoSetter(99999);
                    setter.current = 99;
                    return { width: "100%", height: "100%" };
                  }
                });
              }}
            >
              <animated.img
                src={doubleArrowIcon}
                alt="click here"
                title="click here"
                style={{
                  position: "absolute",
                  width: "8%",
                  height: "8%",
                  zIndex: "10",
                  right: "2%",
                  top: s_height,
                  ...swipePuls,
                }}
              />
              <div
                style={{
                  position: "relative",
                  zIndex: 2,
                  maxWidth: "100%",
                  height: s_height * 2,
                  backgroundImage: `url(${prods.picture})`,
                  backgroundPosition: "50% 15%",
                  backgroundSize: "80%",
                  backgroundAttachment: "fixed",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <img
                  src={prods.picture}
                  alt={`pic${prods.id}`}
                  style={{
                    maxWidth: "100%",
                    height: s_height * 2,
                    visibility: "hidden",
                  }}
                />
              </div>
            </animated.div>
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                right: 0,
                zIndex: 1,
                backgroundColor: "#a9d3e5ad",
                overflow: heightLimit ? "scroll" : "hidden",
              }}
            >
              <div
                style={{
                  width: "30%",
                  height: s_height * 2,
                  position: "absolute",
                  right: "3%",
                  top: s_height / 2,
                  visibility: infoSetter == ind ? "visible" : "hidden",
                }}
              >
                <animated.div
                  style={{ width: "90px", height: "90px", ...scaler }}
                  onMouseOver={() => {
                    setScaler.stop();
                    setScaler.start({ transform: "scale(1.15)" });
                  }}
                  onMouseOut={() => {
                    setScaler.stop();
                    setScaler.start({ transform: "scale(1)" });
                  }}
                >
                  <a
                    href={prods.picture}
                    style={{ textDecoration: "none", listStyleType: "none" }}
                  >
                    <div
                      style={{
                        width: "8vw",
                        fontFamily: "Cantarell",
                        fontSize: "1.2vw",
                        color: "#5b2f98",
                      }}
                    >
                      whole picture
                    </div>
                    <br />
                    <img
                      src={imageIcon}
                      alt="WholeImage"
                      title="whole image"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </a>
                </animated.div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "15%",
                    gap: "10%",
                    color: "#5b2f98",
                    fontSize: "30px",
                    fontWeight: "bold",
                  }}
                >
                  <img
                    src={brush1Icon}
                    alt="Name"
                    title="Name"
                    style={{
                      width: "26%",
                      height: "26%",
                    }}
                  />
                  <h3 style={{ fontFamily: "Cantarell" }}>{prods.name}</h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10%",
                    alignItems: "center",
                    marginTop: "15%",
                    color: "#5b2f98",
                    fontSize: "30px",
                    fontWeight: "bold",
                  }}
                >
                  <img
                    src={cashIcon}
                    alt="Price"
                    title="Price"
                    style={{
                      width: "18%",
                      height: "18%",
                    }}
                  />
                  <h3 style={{ fontFamily: "Cantarell" }}>{prods.price}£</h3>
                </div>
                <animated.div
                  style={contactBu}
                  onMouseOver={() => {
                    setContactBu.stop();
                    setContactBu.start({ transform: "scale(1.15)", x: 32 });
                  }}
                  onMouseOut={() => {
                    setContactBu.stop();
                    setContactBu.start({ transform: "scale(1)", x: 0 });
                  }}
                  onClick={() => {
                    setActiveProd(prods.picture);
                    onMail([prods.name, prods.price, prods.picture]);
                  }}
                >
                  <img
                    src={mailIcon}
                    alt="contact"
                    title="contact me"
                    style={{
                      width: "18%",
                      height: "18%",
                    }}
                  />
                  <span style={{ fontFamily: "Cantarell" }}>
                    Contact Me {">>>"}
                  </span>
                </animated.div>
              </div>
            </div>
          </SingleProd>
        ))}
      </AllProds>
      <animated.div
        style={{
          position: "fixed",
          bottom: "0",
          right: "0",
          ...mailShow, //mailShow ? "flex" : "none",
          width: "100vw",
          height: "100vh",
          backgroundColor: "#000000d6",
          zIndex: 100,
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "5vw",
            height: "5vw",
            top: "20px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            onMail();
            let newState = mailInfo;
            newState["user_prod"] = "";
            setMailInfo(...newState);
          }}
        >
          <img
            src={xCircle}
            alt="xCircle"
            title="cancel"
            style={{ height: "100%", width: "100%" }}
          ></img>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "15%",
            right: "20%",
            display: "flex", //mailShow ? "flex" : "none",
            flexDirection: "column",
            width: "60vw",
            height: "80vh",
            backgroundImage: `url(${backGround})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "70%",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{ fontSize: "30px", color: "#7afdff", fontWeight: "bold" }}
            >
              Product:{" "}
            </p>
            <div style={{ width: "120px", height: "auto" }}>
              <img
                src={activeProd}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{ fontSize: "30px", color: "#7afdff", fontWeight: "bold" }}
            >
              name:
            </p>
            <input
              ref={inputRef}
              type="text"
              name="subject"
              style={{
                backgroundColor: "#022b2cd9",
                color: "white",
                borderRadius: "10px",
                border: "#7afdff solid 1px",
                outline: "none",
                width: "30%",
                height: s_height / 20,
                fontSize: "150%",
              }}
              placeholder="your name"
              onChange={(e) => {
                let newState = mailInfo;
                newState["user_name"] = e.target.value;
                setMailInfo(...newState);
              }}
            />
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{ fontSize: "30px", color: "#7afdff", fontWeight: "bold" }}
            >
              email:
            </p>
            <input
              ref={inputRef2}
              type="text"
              name="subject"
              style={{
                backgroundColor: "#022b2cd9",
                color: "white",
                borderRadius: "10px",
                border: "#7afdff solid 1px",
                outline: "none",
                width: "50%",
                height: s_height / 20,
                fontSize: "150%",
              }}
              placeholder="john@example.de"
              onChange={(e) => {
                let newState = mailInfo;
                newState["user_email"] = e.target.value;
                setMailInfo(...newState);
              }}
            />
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{ fontSize: "30px", color: "#7afdff", fontWeight: "bold" }}
            >
              subject:
            </p>
            <input
              ref={inputRef3}
              type="text"
              name="subject"
              style={{
                backgroundColor: "#022b2cd9",
                color: "white",
                borderRadius: "10px",
                border: "#7afdff solid 1px",
                outline: "none",
                width: "30%",
                height: s_height / 20,
                fontSize: "150%",
              }}
              onChange={(e) => {
                let newState = mailInfo;
                newState["subject"] = e.target.value;
                setMailInfo(...newState);
              }}
            />
          </div>
          <div
            style={{
              width: "70%",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{ fontSize: "30px", color: "#7afdff", fontWeight: "bold" }}
            >
              message:
            </p>
            <textarea
              ref={inputRef4}
              name="message"
              style={{
                backgroundColor: "#022b2cd9",
                color: "white",
                borderRadius: "10px",
                border: "#7afdff solid 1px",
                outline: "none",
                width: "50%",
                height: s_height / 8,
                fontSize: "150%",
                resize: "none",
              }}
              onChange={(e) => {
                let newState = mailInfo;
                newState["message"] = e.target.value;
                setMailInfo(...newState);
              }}
            ></textarea>
            <button
              style={{
                backgroundColor: "#7afdff",
                color: "#022b2cd9",
                borderRadius: "10px",
                border: "#022b2cd9 solid 1px",
                outline: "none",
                width: "30%",
                height: s_height / 20,
                fontSize: "150%",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                if (mailInfo["user_name"]) {
                  if (mailInfo["user_email"]) {
                    if (mailInfo["message"]) {
                      sendEmail(mailInfo);
                      inputRef.current.value = "";
                      inputRef2.current.value = "";
                      inputRef3.current.value = "";
                      inputRef4.current.value = "";
                      setMailShow.stop();
                      setMailShow.start({ opacity: 0 });
                      setMailShow.set({ display: "none" });
                      setMailInfo({
                        user_name: "",
                        user_email: "",
                        subject: "",
                        message: "",
                        user_prod: "",
                      });
                      mailer.current = false;
                    } else {
                      alert("Please enter a message");
                    }
                  } else {
                    alert("Please enter your email address");
                  }
                } else {
                  alert("Please enter your name");
                }

                ////console.log(inputRef4.current.value);
              }}
            >
              send
            </button>
          </div>
        </div>
      </animated.div>
    </MainDiv>
  );
}

export default ArtWork;
