import base from "./api/base";

import ScrollTop from "./components/hooks/ScrolltoTop";

import Navbar from "./components/single_comps/Navbar";
import Footer from "./components/single_comps/Footer";
import HomePage from "./components/pages/HomePage";
import ArtWork from "./components/pages/ArtWork";
import AboutMe from "./components/pages/Aboutme";
import Contact from "./components/pages/Contact";
////////////////////////////////////////////////////////////////
import MobileNavbar from "./components/single_comps/MobileNavbar";
import MobileHomePage from "./components/pagesMobile/MobileHomePage";
import MobileArtWork from "./components/pagesMobile/MobileArtWork";
import MobileAboutMe from "./components/pagesMobile/MobileAboutme";
import MobileContact from "./components/pagesMobile/MobileContact";
////////////////////////////////////////////////////////////////
import useWindowDimensions from "./components/hooks/useWindowDimensions";
import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

function App() {
  const { height, width } = useWindowDimensions();
  const mobileView = useRef(false);
  const [allProds, setAllProds] = useState([]);

  useEffect(() => {
    base("galleryProducts")
      .select({ view: "Grid view" })
      .eachPage((records, fetchNextPage) => {
        //setAllProds(records);
        ////console.log(records);
        let filler = [];
        let objfiller = {};
        records.map((obj, index) => {
          objfiller = {
            name: obj["fields"]["name"],
            price: obj["fields"]["price"],
            picture: obj["fields"]["pictures"][0].url,
          };
          ////console.log(obj["fields"]["pictures"][0]["thumbnails"]["large"].url);
          filler.push(objfiller);

          //console.log("newImages");
          ////console.log(newImages);
        });

        setAllProds(filler);
        fetchNextPage();
      });
  }, []);

  ////console.log(height, width);
  if (width <= 1050) {
    mobileView.current = true;
  } else {
    mobileView.current = false;
  }
  //console.log(height, width);
  const sendEmail = (mailInfo) => {
    ////console.log(mailInfo);
    emailjs
      .send(
        "service_3vu7qst",
        "template_jrvgubv",
        mailInfo,
        "XKjj3LMdLel5XZ0vW"
      )
      .then(
        (result) => {
          ////console.log(result.text);
          alert("message sent successfully");
        },
        (error) => {
          ////console.log(error.text);
          alert("ERROR: Message not sent. Check your entries");
        }
      );
  };
  if (mobileView.current) {
    return (
      <Router>
        <ScrollTop />
        <MobileNavbar s_height={height} s_width={width} />

        <Routes>
          <Route
            path="/"
            exact
            element={
              <MobileHomePage
                s_height={height}
                s_width={width}
                allProds={allProds}
              />
            }
          />
          <Route
            path="/myWork"
            exact
            element={
              <MobileArtWork
                s_height={height}
                s_width={width}
                allProds={allProds}
                sendEmail={sendEmail}
              />
            }
          />
          <Route
            path="/AboutMe"
            exact
            element={<MobileAboutMe s_height={height} s_width={width} />}
          />
          <Route
            path="/Contact"
            exact
            element={
              <MobileContact
                s_height={height}
                s_width={width}
                allProds={allProds}
                sendEmail={sendEmail}
              />
            }
          />
        </Routes>
        <Footer />
      </Router>
    );
  } else {
    return (
      <Router>
        <ScrollTop />
        <Navbar s_height={height} s_width={width} />

        <Routes>
          <Route
            path="/"
            exact
            element={
              <HomePage
                s_height={height}
                s_width={width}
                allProds={allProds}
                onLeave={() => {
                  //console.log("adouiawhduhi");
                }}
              />
            }
          />
          <Route
            path="/myWork"
            exact
            element={
              <ArtWork
                s_height={height}
                s_width={width}
                allProds={allProds}
                sendEmail={sendEmail}
              />
            }
          />
          <Route
            path="/AboutMe"
            exact
            element={<AboutMe s_height={height} s_width={width} />}
          />
          <Route
            path="/Contact"
            exact
            element={
              <Contact
                s_height={height}
                s_width={width}
                allProds={allProds}
                sendEmail={sendEmail}
              />
            }
          />
        </Routes>
        <Footer />
      </Router>
    );
  }
}

export default App;
